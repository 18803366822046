const defaultProps = {
  width: 144,
  height: 16,
  color: '#3A3A3A',
  alt: 'snipfeed logo',
}

interface LogoWordmarkProps {
  width?: number
  height?: number
  color?: string
  alt?: string
  className?: string
}

export default function LogoWordmark({
  width,
  height,
  color,
  alt,
  className,
}: LogoWordmarkProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 144 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      {alt && <title>{alt}</title>}

      <path
        d="M17.4131 7.61584C17.4131 7.61584 15.0318 7.59838 13.8312 7.6308C13.6167 7.63703 13.3973 7.66446 13.1816 7.71059C14.4234 6.77553 14.6004 5.12358 14.093 3.92794H17.4131V7.61584ZM12.9672 11.3985C12.7328 10.8773 12.7515 10.345 13.1043 9.86748C13.3038 9.59818 13.5943 9.45355 13.9272 9.45231C15.1602 9.44358 17.4131 9.44981 17.4131 9.44981V12.0705C17.4131 12.0705 15.2425 12.0667 14.1304 12.0717C13.6068 12.0742 13.1841 11.8797 12.9684 11.3985M7.66722 8.38384C7.66722 8.38384 10.0485 8.40129 11.2491 8.36888C11.4636 8.36264 11.683 8.33522 11.8987 8.28909C10.6582 9.2229 10.4799 10.8761 10.9873 12.0717H7.66722V8.38384ZM11.976 6.1322C11.7765 6.4015 11.486 6.54612 11.1531 6.54737C9.9201 6.5561 7.66722 6.54986 7.66722 6.54986V3.92919C7.66722 3.92919 9.83782 3.93293 10.9499 3.92794C11.4748 3.92545 11.8962 4.11994 12.1131 4.60119C12.3475 5.12233 12.3288 5.6547 11.976 6.1322ZM5.57642 2.09521V13.9032H19.5039V2.09521H5.57642Z"
        fill={color}
      />
      <path
        d="M77.8697 2.02393C77.8697 2.02393 79.8507 2.02393 80.8307 2.02393C80.9466 2.02393 81.0352 2.09873 81.0988 2.17229C83.4638 4.94756 85.8239 7.72657 88.184 10.5043C88.2327 10.5617 88.2863 10.6165 88.3748 10.715V2.03016H90.9905V13.8431C90.9905 13.8431 88.9894 13.8469 88.108 13.8469C87.9384 13.8469 87.8312 13.7908 87.724 13.6636C85.3988 10.917 83.0711 8.17416 80.7422 5.4313C80.6711 5.34777 80.5988 5.26424 80.4903 5.13832V13.8257H77.8684V2.02393H77.8697Z"
        fill={color}
      />
      <path
        d="M101.968 14.1821C99.9472 14.1771 98.1395 13.5849 96.6334 12.206C93.9815 9.77729 94.1224 5.6842 96.9264 3.43007C98.5933 2.08981 100.513 1.59485 102.623 1.73822C104.267 1.84918 105.747 2.38778 107.028 3.4363C109.121 5.15059 109.802 7.91214 108.699 10.3221C108.05 11.7409 106.943 12.7221 105.555 13.4004C104.426 13.9527 103.222 14.1883 101.969 14.1821H101.968ZM101.973 11.6873C102.938 11.6898 103.851 11.4804 104.705 11.0215C107.158 9.70248 107.155 6.47588 105.03 5.0683C103.317 3.93376 100.592 3.93999 98.895 5.09822C96.8179 6.51453 96.854 9.52544 98.9997 10.8707C99.9098 11.4417 100.908 11.6861 101.974 11.6873H101.973Z"
        fill={color}
      />
      <path
        d="M30.8572 2.04492C30.8572 2.04492 35.823 2.02248 38.2367 2.04741C39.572 2.06113 40.7153 2.53988 41.5032 3.67193C42.6914 5.37749 42.4956 8.5031 39.8026 9.59152C39.3339 9.78102 38.8015 9.887 38.2953 9.90071C36.7905 9.94185 33.4791 9.91941 33.4791 9.91941V13.8442H30.8572V2.04492ZM33.4791 7.61915C33.4791 7.61915 36.6035 7.62165 38.1744 7.61666C38.592 7.61417 38.9561 7.43214 39.2054 7.09427C39.6493 6.49458 39.6705 5.82632 39.3775 5.17427C39.1069 4.57084 38.5771 4.33022 37.92 4.33022C36.5012 4.33022 33.4779 4.33146 33.4779 4.33146V7.61915H33.4791Z"
        fill={color}
      />
      <path
        d="M48.4188 11.569H56.3108V13.8456H45.8069V2.04883H48.4188V11.569Z"
        fill={color}
      />
      <path
        d="M123.439 11.5515V13.848H112.942V2.05371H115.548V11.5515H123.439Z"
        fill={color}
      />
      <path
        d="M132.199 7.78513C133.526 6.46482 137.996 2.0376 137.996 2.0376C137.996 2.0376 134.869 2.04383 134.856 2.04383C134.828 2.04383 134.798 2.04757 134.767 2.05505C134.735 2.06253 134.705 2.07251 134.676 2.08497C134.606 2.1124 134.54 2.15105 134.492 2.19718C134.225 2.45401 130.71 5.82149 130.71 5.82149C130.71 5.82149 127.194 2.45526 126.927 2.19718C126.878 2.15105 126.812 2.1124 126.744 2.08497C126.714 2.07251 126.684 2.06129 126.653 2.05505C126.622 2.04882 126.592 2.04383 126.563 2.04383C126.549 2.04383 123.424 2.0376 123.424 2.0376C123.424 2.0376 127.893 6.46482 129.22 7.78513C129.343 7.90856 129.391 8.02949 129.391 8.20279C129.386 9.61287 129.397 13.8481 129.397 13.8481H132.024C132.024 13.8481 132.035 9.61412 132.03 8.20279C132.03 8.03074 132.077 7.90856 132.201 7.78513"
        fill={color}
      />
      <path
        d="M68.473 2.16592V2.17215C68.473 2.17215 68.473 2.16841 68.4705 2.16717C68.4069 2.04 68.3271 2.02628 68.2112 2.02628C68.1875 2.02379 65.9533 2.02379 65.9296 2.02628C65.8137 2.02628 65.7339 2.04 65.6703 2.16717C63.7466 6.02088 59.8455 13.838 59.8455 13.838C59.8455 13.838 62.1569 13.838 62.4599 13.838C62.6369 13.838 62.7105 13.7719 62.7803 13.6273C63.0484 13.0688 63.2915 12.5252 63.5458 11.9741C63.6356 11.7884 63.7266 11.6026 63.8151 11.4168C63.8812 11.2784 63.9597 11.2248 64.1143 11.2236C65.0993 11.2261 69.7522 11.2236 70.0289 11.2236C70.1835 11.2248 70.2621 11.2772 70.3282 11.4168C70.4167 11.6026 70.5077 11.7884 70.5975 11.9741C70.8518 12.5252 71.0949 13.0688 71.363 13.6273C71.4328 13.7719 71.5063 13.838 71.6834 13.838C71.9863 13.838 74.2978 13.838 74.2978 13.838C74.2978 13.838 70.3967 6.01963 68.473 2.16717V2.16592ZM65.0407 8.9146L67.0704 4.74545L69.1001 8.9146H65.0419H65.0407Z"
        fill={color}
      />
    </svg>
  )
}

LogoWordmark.defaultProps = defaultProps
