import { useMutation } from '@apollo/client'
import { getSession, signIn, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { ReactNode, useEffect, useState } from 'react'

import {
  FindOrCreatePlanolyUserInput,
  FindOrCreatePlanolyUserPayload,
  FindOrCreatePlanolyUserStrategy,
} from '@snipfeed/graphql/types'
import { Loader, toast } from '@snipfeed/tint2'

import FIND_OR_CREATE_PLANOLY_USER from '@/graphql/mutations/findOrCreatePlanolyUser.gql'
import useLogout from '@/hooks/useLogout'
import { resetApolloSession } from '@/lib/apolloClient'
import { serverErrorMessage } from '@/settings/constants'
import { IS_NEW_ACCOUNT_SESSION_KEY } from '@/utils/constants'
import { isGraphQLForbiddenError } from '@/utils/helpers'

interface PlanolyAuthGuardProps {
  children: ReactNode
}

const PlanolyAuthGuard = ({
  children,
}: PlanolyAuthGuardProps): JSX.Element | null => {
  const router = useRouter()

  const { onLogout } = useLogout()
  const { update } = useSession()
  const [isPlanolyAuthorized, setIsPlanolyAuthorized] = useState<boolean>(false)

  const [findOrCreatePlanolyUser] = useMutation<
    { findOrCreatePlanolyUser: FindOrCreatePlanolyUserPayload },
    { input: FindOrCreatePlanolyUserInput }
  >(FIND_OR_CREATE_PLANOLY_USER)

  useEffect(() => {
    const handlePlanolyAuthentication = async () => {
      if (!router.isReady) {
        return
      }

      let authToken = router.query.authToken as string | undefined
      let socialSetId = router.query.socialSetId as string | undefined

      // Remove sensitive data from URL
      const newPath = {
        pathname: router.pathname,
        query: { ...router.query },
      }
      delete newPath.query.authToken
      delete newPath.query.socialSetId
      router.replace(newPath, undefined, { shallow: true })

      // Use session as fallback for auth details
      if (!authToken || !socialSetId) {
        // Using getSession instead of useSession we can make sure that this logic only runs once, on this component mount.
        const session = await getSession()
        authToken = session?.planolyToken
        socialSetId = session?.socialSetId
      }

      //This is not a Planoly Auth attempt we should just let the user continue
      if (!authToken || !socialSetId) {
        setIsPlanolyAuthorized(true)
        return
      }

      try {
        const { data } = await findOrCreatePlanolyUser({
          variables: {
            input: {
              token: authToken,
              socialSetId: parseInt(socialSetId),
              strategy: FindOrCreatePlanolyUserStrategy.Default,
            },
          },
        })

        if (!data) {
          throw new Error('Failed to authenticate with Planoly')
        }

        const { findOrCreatePlanolyUser: result } = data

        if (result.__typename === 'FindOrCreatePlanolyUserPayloadSuccess') {
          if (result.isNew) {
            sessionStorage.setItem(IS_NEW_ACCOUNT_SESSION_KEY, 'true')
          }
          await signIn<'credentials'>('credentials', {
            strategy: 'token',
            token: result.token,
            redirect: false,
            planolyToken: authToken,
            workspaceName: result.workspaceName,
            socialSetId,
          })
          await update()
          await resetApolloSession()
        } else if (result.__typename === 'AccountAvailableForLinkingPayload') {
          await router.push(
            `/welcome/planoly?email=${result.email}&authToken=${authToken}&socialSetId=${socialSetId}`
          )
          return
        } else {
          throw result
        }

        setIsPlanolyAuthorized(true)
      } catch (error) {
        if (isGraphQLForbiddenError(error)) {
          await onLogout('/403?isPlanoly')
          return
        }
        onLogout()
        toast.error(error.message || serverErrorMessage)
      }
    }

    handlePlanolyAuthentication()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady])

  //TODO: Global loader here maybe ?
  return isPlanolyAuthorized ? <>{children}</> : <Loader global />
}

export default PlanolyAuthGuard
