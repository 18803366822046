/**
 * This file was generated using Svgr
 * DO NOT MAKE CHANGES TO THIS FILE DIRECTLY
 * If you need to manually add new icons use "./custom" directory
 */

import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCheckCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#check-circle_svg__a)">
      <path d="M8 .5C3.86.5.5 3.86.5 8c0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5C15.5 3.86 12.14.5 8 .5Zm0 14c-3.58 0-6.5-2.92-6.5-6.5S4.42 1.5 8 1.5s6.5 2.92 6.5 6.5-2.92 6.5-6.5 6.5Z" />
      <path d="m9.92 6.07-2.8 2.8-1.05-1.05c-.2-.2-.51-.2-.71 0-.2.2-.2.51 0 .71l1.4 1.4a.485.485 0 0 0 .7 0l3.15-3.15c.2-.2.2-.51 0-.71-.2-.2-.51-.2-.71 0h.02Z" />
    </g>
    <defs>
      <clipPath id="check-circle_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgCheckCircle
