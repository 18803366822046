import { SubscriptionProductType } from '@/components/Pricing/types'

const API_URL = process.env.NEXT_PUBLIC_API_URL || ''

const ONE_DAY = 24 * 60 * 60 * 1000
export function isUserNew(createdAtDate: Date) {
  return Date.now() - ONE_DAY < createdAtDate.getTime()
}

export const getCreatorById = async (creatorId) => {
  const query = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `ApiKey ${process.env.BACKEND_API_KEY}`,
    },
    body: JSON.stringify({
      query: `
      query creatorById($creatorId: ID!) {
        creatorById(creatorId: $creatorId) {
          stripeCustomerId
          isUpgraded
        }
      }
    `,
      variables: {
        creatorId,
      },
    }),
  })
  const result = await query.json()
  return result.data?.creatorById ?? {}
}

// helps inferring the billing cycle
const BILLING_CYCLES = ['monthly', 'yearly'] as const

export const getBillingCycleByPriceId = (
  plan: SubscriptionProductType,
  priceId?: string
) => {
  for (const billingPeriod of BILLING_CYCLES) {
    if (plan.prices[billingPeriod].id === priceId) {
      return billingPeriod
    }
  }
  return null // return null if priceId not found
}
