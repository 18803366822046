/**
 * This file was generated using Svgr
 * DO NOT MAKE CHANGES TO THIS FILE DIRECTLY
 * If you need to manually add new icons use "./custom" directory
 */

import * as React from 'react'
import type { SVGProps } from 'react'
const SvgInfoCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#info-circle_svg__a)">
      <path d="M8 .5C3.86.5.5 3.86.5 8c0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5C15.5 3.86 12.14.5 8 .5Zm0 14c-3.58 0-6.5-2.92-6.5-6.5S4.42 1.5 8 1.5s6.5 2.92 6.5 6.5-2.92 6.5-6.5 6.5Z" />
      <path d="M8 6.85c-.28 0-.5.22-.5.5v3.74c0 .28.22.5.5.5s.5-.22.5-.5V7.35c0-.28-.22-.5-.5-.5ZM8 5.11c-.28 0-.5.22-.5.5s.22.5.5.5.5-.23.5-.5-.22-.5-.5-.5Z" />
    </g>
    <defs>
      <clipPath id="info-circle_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgInfoCircle
