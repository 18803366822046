import React from 'react'

import ErrorIcon from './icons/ErrorIcon'
import { cva } from 'class-variance-authority'
import { cn } from '../utils/className'

interface InputErrorProps {
  children: React.ReactNode
  className?: string
}

const inputLabelVariants = cva('flex items-center gap-2 mt-2')

const InputError = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<InputErrorProps>
>(function InputError({ className, children, ...props }, ref) {
  return (
    <div className={cn(inputLabelVariants(), className)} ref={ref}>
      <ErrorIcon />

      <p
        className="text-2xs text-cherry-800 first-letter:capitalize"
        role="alert"
        {...props}
      >
        {children}
      </p>
    </div>
  )
})

export default InputError
