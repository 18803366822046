import { useQuery } from '@apollo/client'
import { PropsWithChildren } from 'react'

import { CheckCircle } from '@snipfeed/icons'
import { Modal } from '@snipfeed/tint2'
import { ModalProps } from '@snipfeed/tint2/components/Modal'

import GET_PROFILE from '@/graphql/queries/profile.gql'

type JoinProModalProps = Partial<ModalProps> &
  PropsWithChildren<{
    onClose(): void
    onFindOutMore(): void
  }>

const proPlanFeatures = [
  'Custom domain (yourpage.com)',
  'Remove Planoly branding',
  'Blue tick verification for your page',
  'Advanced analytics and insights',
  'Exclusive premium feature access',
]

export default function JoinProModal({
  title,
  actions,
  onClose,
  onFindOutMore,
  children,
}: JoinProModalProps): JSX.Element {
  const { data: { profile = {} } = {} } = useQuery(GET_PROFILE, {
    fetchPolicy: 'cache-first',
  })

  return (
    <Modal
      title={title ?? { text: 'Upgrade to unlock this feature and much more' }}
      actions={
        actions ?? {
          primary: {
            text: profile?.isEligibleForTrial
              ? 'Start my 2 week free trial'
              : 'Let’s go!',
            onClick: onFindOutMore,
          },
          secondary: { text: 'No thanks', onClick: onClose },
        }
      }
      size="md"
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          onClose()
        }
      }}
      isOpen
    >
      {children && children}
      {!children && (
        <ul>
          {proPlanFeatures.map((feature) => (
            <li key={feature} className="mt-3 first-of-type:mt-0">
              <div className="flex items-center gap-2">
                <CheckCircle className="text-cornflower-700" />

                <span className="text-2xs">{feature}</span>
              </div>
            </li>
          ))}
        </ul>
      )}
    </Modal>
  )
}
