import { cva } from 'class-variance-authority'
import React, {
  MouseEvent,
  PropsWithoutRef,
  RefAttributes,
  useImperativeHandle,
  useRef,
} from 'react'
import { IconProps } from '../utils/prop-types'
import Loader from './Loader'
import CaretDownIcon from './icons/CaretDown'

export type ButtonVariants =
  | 'primary'
  | 'secondary'
  | 'interactive'
  | 'danger'
  | 'danger-outline'
  | 'icon'
type ButtonSizes = 'sm' | 'md'
type IconPositions = 'left' | 'right'
type IconType =
  | React.ComponentType<React.PropsWithChildren<IconProps>>
  | React.ElementType
  | null
interface ButtonProps {
  variant?: ButtonVariants
  size?: ButtonSizes
  fullWidth?: boolean
  icon?: IconType
  iconPosition?: IconPositions
  loading?: ConstrainBoolean
  children?: React.ReactNode
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement>
  disclosure?: boolean
  ghost?: boolean
  bordered?: boolean
  asChild?: boolean
  inList?: boolean
  className?: string
}

const defaultProps = {
  variant: 'primary' as ButtonVariants,
  size: 'sm' as ButtonSizes,
  fullWidth: false,
  iconPosition: 'left' as IconPositions,
  loading: false,
  disabled: false,
  disclosure: false,
  ghost: false,
  bordered: false,
  inList: false,
  className: '',
}

type NativeAttrs = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement | HTMLDivElement | null>,
  keyof ButtonProps
>
export type ButtonPropsType = ButtonProps & typeof defaultProps & NativeAttrs

export const buttonVariants = cva(
  'group flex items-center justify-center rounded text-2xs font-medium focus-visible:outline-none focus-visible:ring focus-visible:ring-focused focus-visible:ring-offset-1 disabled:cursor-not-allowed ring-cornflower-600',
  {
    variants: {
      variant: {
        primary: '',
        secondary: '',
        danger: '',
        'danger-outline': '',
        interactive: '',
        icon: `
          bg-transparent text-neutral-700

          hover:bg-neutral-100 hover:text-neutral-600

          active:bg-neutral-150 active:text-neutral-1000

          disabled:bg-white disabled:text-neutral-400
        `,
      },
      size: { sm: '', md: '' },
      fullWidth: {
        true: 'w-full',
        false: 'w-auto',
      },
      iconPosition: {
        left: '',
        right: '',
      },
      ghost: {
        true: 'bg-transparent',
      },
      bordered: {
        true: '',
      },
      disabled: {
        true: 'disabled:text-neutral-400',
      },
      inList: {
        true: '!p-0',
      },
    },
    compoundVariants: [
      // variant="primary" && ghost=false
      {
        variant: 'primary',
        ghost: false,
        disabled: false,
        className: `
          bg-neutral-800 text-white border border-neutral-1000

          hover:bg-neutral-1000

          active:bg-neutral-1000
        `,
      },
      {
        variant: 'secondary',
        ghost: false,
        disabled: false,
        className: `
          bg-neutral-150 text-neutral-800 border border-neutral-300

          hover:bg-neutral-300 hover:border-neutral-400

          active:bg-neutral-400 active:border-neutral-200
        `,
      },
      // variant="primary | secondary" && ghost=true
      {
        variant: ['primary', 'secondary'],
        ghost: true,
        className: `
          text-neutral-900

          hover:bg-neutral-150 hover:text-neutral-700

          active:text-text-neutral-1000
        `,
      },
      // variant="danger" && ghost=false
      {
        variant: 'danger',
        ghost: false,
        disabled: false,
        className: `
          bg-cherry-700 text-cherry-100 border border-cherry-800

          hover:bg-cherry-900 hover:border-cherry-1000

          active:bg-cherry-1000 active:border-cherry-800'
        `,
      },
      // variant="danger-outline" && ghost=false
      {
        variant: 'danger-outline',
        ghost: false,
        className: `
          text-cherry-700 border border-cherry-800

          hover:bg-cherry-200 hover:border-cherry-700

          active:bg-cherry-300 active:border-cherry-700'

          disabled:border disabled:border-neutral-400 disabled:bg-transparent
        `,
      },
      // variant="interactive" && ghost=true
      {
        variant: 'interactive',
        ghost: true,
        className: `
          text-cornflower-600
          
          hover:text-cornflower-400
          
          active:text-cornflower-900
        `,
      },
      // variant="interactive" && ghost=false
      {
        variant: 'interactive',
        ghost: false,
        disabled: false,
        className: [
          'border',
          // button colors
          'bg-cornflower-600 border-cornflower-400 text-white',

          // button hover colors
          'hover:bg-cornflower-800 hover:border-cornflower-600',

          // active button colors
          'active:bg-cornflower-900 active:border-cornflower-700',

          // disabled button colors
          'disabled:bg-neutral-100 disabled:border-neutral-100 disabled:text-neutral-400',
        ],
      },
      // variant="danger" && ghost=true
      {
        variant: 'danger',
        ghost: true,
        className: `
          text-cherry-800
          
          hover:text-cherry-600
          
          active:text-cherry-1000
        `,
      },
      // variant="danger-outline" && ghost=true
      {
        variant: 'danger-outline',
        ghost: true,
        className: `
          text-cherry-700
        `,
      },
      {
        disabled: true,
        ghost: false,
        className: 'disabled:bg-neutral-100',
      },
      {
        disabled: true,
        ghost: true,
        className: 'disabled:bg-transparent',
      },
      {
        variant: [
          'primary',
          'secondary',
          'danger',
          'danger-outline',
          'interactive',
        ],
        size: 'sm',
        inList: false,
        className: 'w-auto px-4 py-2',
      },
      {
        variant: [
          'primary',
          'secondary',
          'danger',
          'danger-outline',
          'interactive',
        ],
        size: 'md',
        className: 'w-auto px-5 py-4',
      },
      {
        variant: 'icon',
        size: 'sm',
        className: ['w-6', 'p-1'],
      },
      {
        variant: 'icon',
        size: 'md',
        className: ['w-9', 'p-2'],
      },
      {
        variant: 'icon',
        bordered: true,
        className: 'border border-neutral-200',
      },
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'sm',
      fullWidth: false,
      ghost: false,
    },
  }
)

/* eslint-disable react/display-name */
const Button = React.forwardRef<
  HTMLButtonElement | HTMLDivElement | null,
  React.PropsWithChildren<ButtonPropsType>
>(
  (
    { ...btnProps },
    ref: React.Ref<HTMLButtonElement | HTMLDivElement | null>
  ) => {
    if (btnProps.variant === 'icon' && !btnProps.icon) {
      throw new Error("icon component is required when variant='icon'!")
    }
    if (btnProps.variant !== 'icon' && btnProps.bordered) {
      throw new Error("Only button type='icon' can be optionally bordered")
    }
    if (btnProps.ghost && btnProps.loading) {
      throw new Error("ghost icons aren't supposed to trigger async actions")
    }

    const buttonRef = useRef(null)
    useImperativeHandle(ref, () => buttonRef.current)
    const {
      variant,
      size,
      fullWidth,
      icon: Icon,
      iconPosition,
      loading,
      children,
      className,
      disabled,
      ghost,
      bordered,
      disclosure,
      inList,
      asChild = false,
      onClick,
      ...props
    } = btnProps

    const isIconOnly = Icon && variant === 'icon'
    const showDisclosureCaret = !Icon && variant === 'secondary' && disclosure
    const Component = asChild ? 'div' : 'button'

    const clickHandler = (
      event: MouseEvent<HTMLButtonElement | HTMLDivElement>
    ) => {
      if (disabled) {
        return
      }
      onClick && onClick(event)
    }

    return (
      <Component
        ref={buttonRef}
        onClick={clickHandler}
        disabled={disabled || loading}
        {...props}
        className={`${buttonVariants({
          variant,
          size,
          fullWidth,
          iconPosition,
          ghost: ghost && !isIconOnly,
          bordered,
          disabled: disabled || loading,
          inList,
        })} ${className}`}
      >
        {loading ? (
          <>
            <Loader className="mr-2.5" />
            {children}
          </>
        ) : isIconOnly ? (
          <Icon
            width={size === 'sm' ? 16 : 20}
            height={size === 'sm' ? 16 : 20}
          />
        ) : (
          <>
            {Icon && (!iconPosition || iconPosition === 'left') && (
              <div className="mr-1">
                <Icon width={18} height={18} />
              </div>
            )}
            {children}

            {Icon && iconPosition === 'right' && (
              <div className="ml-2.5">
                <Icon width={18} height={18} />
              </div>
            )}
            {showDisclosureCaret && (
              <CaretDownIcon
                className="ml-2.5 w-2 text-neutral-500 group-hover:text-neutral-1000"
                width={8}
                height={5}
              />
            )}
          </>
        )}
      </Component>
    )
  }
)

type ButtonComponent<T, P> = React.ForwardRefExoticComponent<
  PropsWithoutRef<P> & RefAttributes<T>
>

type ComponentProps = Partial<typeof defaultProps> &
  Omit<ButtonProps, keyof typeof defaultProps> &
  NativeAttrs

Button.defaultProps = defaultProps

export default React.memo(Button) as ButtonComponent<
  HTMLButtonElement | HTMLDivElement | null,
  ComponentProps
>
