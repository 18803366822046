import NextLink from 'next/link'
import { LinkExternal } from '@snipfeed/icons'
import { cva } from 'class-variance-authority'
import { cn } from '../utils/className'

interface LinkProps extends React.ComponentProps<typeof NextLink> {
  isExternal?: boolean
}

const linkVariants = cva(
  'text-2xs text-cornflower-700 hover:text-cornflower-400 active:text-cornflower-900',
  {
    variants: {
      isExternal: {
        true: 'inline-flex items-center',
      },
    },
  }
)

export default function Link({
  children,
  className,
  isExternal,
  ...props
}: LinkProps) {
  return (
    <NextLink
      {...props}
      className={cn(linkVariants({ isExternal }), className)}
    >
      {children}
      {isExternal && <LinkExternal width={12} height={12} className="ml-1" />}
    </NextLink>
  )
}
