import { useQuery } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'
import { signIn, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@snipfeed/forms/V2'
import Image from '@snipfeed/image'
import { Button, Input, Link, toast } from '@snipfeed/tint2'

import GoogleAuthButton from '@/components/common/GoogleAuthButton'
import GET_PROFILE from '@/graphql/queries/profile.gql'
import useTokenSignin from '@/hooks/useTokenSignin'
import logoMark from '@/public/icons/logo/mark.svg'
import { NEXT_AUTH_ERRORS, serverErrorMessage } from '@/settings/constants'

const PlanolyUrl = process.env.NEXT_PUBLIC_PLANOLY_URL

const FormSchema = z.object({
  email: z.string().email(),
  password: z.string().min(1, 'Password is required'),
})

export type LoginFormValues = z.infer<typeof FormSchema>

export default function LoginPage() {
  const router = useRouter()
  const { error } = router.query
  const { status } = useSession()
  const { data: { profile: user } = {} } = useQuery(GET_PROFILE, {
    skip: status !== 'authenticated',
  })

  useEffect(() => {
    if (status === 'authenticated' && user?.id) {
      router.push('/')
    }
  }, [router, status, user?.id])

  const form = useForm<LoginFormValues>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const [isLoading, setIsLoading] = useState(false)

  useTokenSignin({
    onSuccess: useCallback(() => {
      router.replace('/')
    }, [router]),
    onProcessing: useCallback(() => setIsLoading(true), []),
    onFailure: useCallback((error) => {
      setIsLoading(false)
      toast.error(
        error?.message || error?.error === 'CredentialsSignin'
          ? 'Wrong Login and Password combination, please try again'
          : serverErrorMessage
      )
    }, []),
  })

  const onSubmit = useCallback(
    async (values: LoginFormValues) => {
      setIsLoading(true)

      try {
        const result = await signIn<'credentials'>('credentials', {
          ...values,
          strategy: 'password',
          callbackUrl: '/',
          redirect: true,
        })
        //todo: confirm when redirect is true, result is always undefined when success full
        if (result && !result.ok) {
          throw result
        }
      } catch (e) {
        toast.error(
          e?.message || e?.error === 'CredentialsSignin'
            ? 'Wrong Login and Password combination, please try again'
            : serverErrorMessage
        )
      } finally {
        setIsLoading(false)
      }
    },
    [toast]
  )

  useEffect(() => {
    if (error) {
      toast.error(NEXT_AUTH_ERRORS[error as string] ?? NEXT_AUTH_ERRORS.default)
    }
  }, [error])

  return (
    <div className="w-full max-w-[480px]">
      <h1 className="text-2xl mb-8 font-normal font-decorative">
        Welcome back to Creator Store
      </h1>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-neutral-800 mb-2">Email</FormLabel>
                <FormControl>
                  <Input type="email" placeholder="Email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className="mb-4">
                <FormLabel className="text-neutral-800 mb-2">
                  Password
                </FormLabel>
                <FormControl>
                  <Input type="password" placeholder="Password" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button
            type="submit"
            variant="interactive"
            size="md"
            loading={isLoading}
            className="w-full mt-6 h-11 mb-2"
            disabled={!form.formState.isValid}
          >
            Login with email
          </Button>

          <Link href="/forgot-password" className="no-underline mb-6">
            FORGOT PASSWORD
          </Link>

          <div className="flex items-center text-neutral-700 my-4">
            <hr className="flex-grow border-t border-neutral-200" />
            <div className="px-2">or</div>
            <hr className="flex-grow border-t border-neutral-200" />
          </div>

          <GoogleAuthButton />

          <a
            href={`${PlanolyUrl}/register`}
            target="_blank"
            rel="noreferrer noopener"
          >
            <Button
              type="button"
              variant="secondary"
              className="w-full mb-6 h-11 bg-white"
              icon={() => (
                <Image
                  src={logoMark}
                  alt="Planoly"
                  width={24}
                  height={24}
                  className="w-[24px] min-w-[24px] mr-3"
                />
              )}
              asChild
            >
              Login with Planoly
            </Button>
          </a>

          <div className="text-3xsleading-6 text-neutral-700 mb-20">
            {`Don't have an account? `}
            <Link href="/signup" className="no-underline text-base">
              Sign up
            </Link>
          </div>

          <div className="text-neutral-700 mb-6">
            <div className="mb-6">
              By continuing you agree to Planoly&apos;s{' '}
              <Link
                href="https://public.snipfeed.co/terms-and-conditions.pdf"
                className="no-underline text-base"
              >
                Terms of Service
              </Link>{' '}
              and{' '}
              <Link
                href="https://public.snipfeed.co/privacy-policy.pdf"
                className="no-underline text-base"
              >
                Privacy Policy
              </Link>
              .
            </div>
            <div>2024 All Rights Reserved.</div>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}
