import React from 'react'

export default function ErrorIcon() {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM10.7273 7.09091C10.7273 6.68925 10.4017 6.36364 10 6.36364C9.59834 6.36364 9.27273 6.68925 9.27273 7.09091V10C9.27273 10.4017 9.59834 10.7273 10 10.7273C10.4017 10.7273 10.7273 10.4017 10.7273 10V7.09091ZM10 12.1818C9.59834 12.1818 9.27273 12.5074 9.27273 12.9091C9.27273 13.3108 9.59834 13.6364 10 13.6364H10.0073C10.4089 13.6364 10.7345 13.3108 10.7345 12.9091C10.7345 12.5074 10.4089 12.1818 10.0073 12.1818H10Z"
        fill="#7D2B28"
      />
    </svg>
  )
}
