import React from 'react'

export default function AlertCircle({
  className = 'fill-cherry-600',
}: {
  className?: string
}) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6772 1.39029C10.2461 1.1986 9.75393 1.1986 9.32283 1.39029C8.98989 1.53833 8.77361 1.7991 8.62311 2.01158C8.47514 2.22048 8.31672 2.49417 8.14249 2.79516L1.2532 14.6949C1.07827 14.9969 0.919309 15.2715 0.811525 15.5045C0.701984 15.7412 0.583147 16.0595 0.621021 16.4228C0.67003 16.8929 0.916314 17.32 1.29858 17.598C1.59399 17.8128 1.92899 17.8694 2.18879 17.8932C2.44441 17.9167 2.76162 17.9167 3.1107 17.9166H16.8893C17.2383 17.9167 17.5556 17.9167 17.8112 17.8932C18.071 17.8694 18.406 17.8128 18.7014 17.598C19.0837 17.32 19.33 16.8929 19.379 16.4228C19.4168 16.0595 19.298 15.7412 19.1885 15.5044C19.0807 15.2715 18.9217 14.997 18.7468 14.6949L11.8575 2.79514C11.6833 2.49416 11.5248 2.22047 11.3769 2.01157C11.2264 1.7991 11.0101 1.53833 10.6772 1.39029ZM10.8334 7.49998C10.8334 7.03974 10.4603 6.66665 10 6.66665C9.53978 6.66665 9.16669 7.03974 9.16669 7.49998V10.8333C9.16669 11.2935 9.53978 11.6666 10 11.6666C10.4603 11.6666 10.8334 11.2935 10.8334 10.8333V7.49998ZM10 13.3333C9.53978 13.3333 9.16669 13.7064 9.16669 14.1666C9.16669 14.6269 9.53978 15 10 15H10.0084C10.4686 15 10.8417 14.6269 10.8417 14.1666C10.8417 13.7064 10.4686 13.3333 10.0084 13.3333H10Z"
        fill="currentColor"
      />
    </svg>
  )
}
