import { cva } from 'class-variance-authority'
import CloseIcon from './icons/CloseIcon'
import { InfoCircle, AlertCircle } from '@snipfeed/icons'
import SuccessIcon from './icons/Success'

export enum NotificationTypes {
  Neutral = 'neutral',
  Success = 'success',
  Informational = 'informational',
  Warning = 'warning',
  Error = 'error',
  Primary = 'primary',
  Dark = 'dark',
}

const notificationTypesToIcon = {
  [NotificationTypes.Neutral]: InfoCircle,
  [NotificationTypes.Success]: SuccessIcon,
  [NotificationTypes.Informational]: InfoCircle,
  [NotificationTypes.Warning]: AlertCircle,
  [NotificationTypes.Error]: AlertCircle,
  [NotificationTypes.Primary]: AlertCircle,
  [NotificationTypes.Dark]: InfoCircle,
}

interface NotificationProps {
  type?: NotificationTypes
  message: string
  onClose?: () => void
  actions?: React.ReactNode
  rectangle?: boolean
}

function NotificationIcon({ type }: { type: NotificationTypes }) {
  const Icon = notificationTypesToIcon[type]

  const iconVariants = cva([], {
    variants: {
      type: {
        [NotificationTypes.Neutral]: ['fill-neutral-800', 'text-neutral-100'],
        [NotificationTypes.Success]: ['fill-leaf-600', 'text-leaf-100'],
        [NotificationTypes.Informational]: [
          'fill-cornflower-700',
          'text-cornflower-200',
        ],
        [NotificationTypes.Warning]: ['fill-gold-600', 'text-gold-100'],
        [NotificationTypes.Error]: ['fill-cherry-600', 'text-cherry-100'],
        [NotificationTypes.Primary]: ['fill-berry-600', 'text-berry-100'],
        [NotificationTypes.Dark]: ['fill-white', 'text-black'],
      },
    },
    defaultVariants: {
      type: NotificationTypes.Primary,
    },
  })

  return (
    <div className="mr-4">
      <Icon width={20} height={20} className={iconVariants({ type })} />
    </div>
  )
}

export default function Notification({
  type = NotificationTypes.Primary,
  message,
  onClose,
  actions,
  rectangle,
}: NotificationProps) {
  const notificationVariants = cva(
    [
      // 'z-10',
      'relative',
      'w-full',
      'py-4',
      'rounded-sm',
      'flex',
      'items-center',
      'text-sm',
      'px-3',
      'font-normal',
      'flex-wrap',
      'gap-4',
      'justify-between',
      'border',
    ],
    {
      variants: {
        type: {
          [NotificationTypes.Neutral]: 'bg-neutral-100 border-neutral-300',
          [NotificationTypes.Success]: 'bg-leaf-100 border-leaf-800',
          [NotificationTypes.Informational]:
            'bg-cornflower-200 border-cornflower-700',
          [NotificationTypes.Warning]: 'bg-gold-100 border-gold-800',
          [NotificationTypes.Error]: 'bg-cherry-100 border-cherry-800',
          [NotificationTypes.Primary]: 'bg-berry-100 border-berry-600',
          [NotificationTypes.Dark]: ['text-white', 'bg-black'],
        },
        rectangle: {
          true: ['rounded-none'],
        },
      },
      defaultVariants: {
        type: NotificationTypes.Primary,
      },
    }
  )

  return (
    <div className={notificationVariants({ type, rectangle })}>
      <div className="flex">
        {<NotificationIcon type={type} />}

        <span className="text-2xs">{message}</span>
      </div>
      <div className="cursor-pointer flex items-center">
        {actions}

        {onClose && (
          <div onClick={() => onClose()} className="ml-5">
            <CloseIcon />
          </div>
        )}
      </div>
    </div>
  )
}
