import * as DialogPrimitive from '@radix-ui/react-dialog'
import { cva } from 'class-variance-authority'
import React from 'react'
import { IconProps } from '../utils/prop-types'
import Button, { ButtonVariants } from './Button'
import { cn } from '../utils/className'
import { XClose } from '@snipfeed/icons'

type IconType = React.ComponentType<React.PropsWithChildren<IconProps>> | null
type ModalTitleType = {
  text: string
  icon?: IconType
}
type ModalActionsType = {
  primary: {
    text: string
    onClick: () => void
    variant?: ButtonVariants
    disabled?: boolean
    loading?: boolean
  }
  secondary?: {
    text: string
    onClick: () => void
    disabled?: boolean
    loading?: boolean
  }
}

export interface ModalProps {
  children: React.ReactNode
  isOpen?: boolean
  defaultOpen?: boolean
  onOpenChange?: (open: boolean) => void
  title?: ModalTitleType
  description?: string
  size?: 'sm' | 'md' | 'lg' | 'xl'
  actions?: ModalActionsType
  className?: string
}

export const modalContentVariant = cva(
  [
    // decorative styles
    'bg-neutral-50 shadow-xl',

    // replacment
    'fixed z-30 bottom-0 max-h-[95%] overflow-y-auto',

    // spacing
    `border-none p-6 w-full rounded-tl-3xl	rounded-tr-3xl`,

    // focus
    'focus:outline-none focus-visible:ring focus-visible:ring-offset-1 focus-visible:ring-cornflower-600',

    //* --|| Desktop ||-- *//

    // replacment
    'md:top-1/2 md:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2	md:bottom-[unset]',

    // spacing
    'md:rounded-2xl',

    'font-regular',
  ],
  {
    variants: {
      size: {
        sm: 'md:w-[25rem]',
        md: 'w-full max-w-[36rem]',
        lg: 'w-full max-w-[50rem]',
        xl: 'w-full max-w-[60rem]',
      },
      isOpen: {
        true: 'animate-modal-show',
        false: 'animate-modal-exit',
      },
    },
    defaultVariants: {
      size: 'lg',
      isOpen: false,
    },
  }
)

const Modal = ({
  title: { text: titleText, icon: Icon } = { text: '' },
  description,
  actions,
  isOpen,
  size = 'lg',
  onOpenChange,
  children,
  defaultOpen,
  className,
}: ModalProps) => {
  return (
    <DialogPrimitive.Root
      open={isOpen}
      onOpenChange={onOpenChange}
      defaultOpen={defaultOpen}
      modal
    >
      <DialogPrimitive.Portal forceMount>
        {isOpen && (
          <>
            <DialogPrimitive.Overlay
              forceMount
              className="fixed inset-0 z-20 bg-black/50"
            />
            <DialogPrimitive.Content
              forceMount
              className={cn(modalContentVariant({ isOpen, size }), className)}
            >
              <div className="flex items-center justify-between">
                {titleText && (
                  <DialogPrimitive.Title className="text-base font-semibold text-neutral-800 flex items-center font-regular">
                    {Icon && (
                      <div className="mr-4">
                        <Icon width={22} height={22} />
                      </div>
                    )}
                    {titleText}
                  </DialogPrimitive.Title>
                )}

                <DialogPrimitive.Close
                  data-testid="modal-close-button"
                  className="top-4 right-4 focus:outline-none focus-visible:ring focus-visible:ring-ring"
                >
                  <XClose className="h-6 w-6 text-neutral-700 hover:text-neutral-800" />
                </DialogPrimitive.Close>
              </div>

              <div className="mt-6">{children}</div>

              {description && (
                <DialogPrimitive.Description className="mt-4 text-2xs font-normal text-neutral-800">
                  {description}
                </DialogPrimitive.Description>
              )}

              {actions && (
                <div className="mt-6 flex justify-end gap-2">
                  {actions.secondary && (
                    <DialogPrimitive.Close asChild>
                      <Button
                        variant="secondary"
                        className="w-full md:w-auto"
                        onClick={actions.secondary.onClick}
                        disabled={actions.secondary.disabled}
                        loading={actions.secondary.loading}
                      >
                        {actions.secondary.text}
                      </Button>
                    </DialogPrimitive.Close>
                  )}

                  {actions.primary && (
                    <Button
                      onClick={actions.primary.onClick}
                      className="w-full md:w-auto"
                      variant={actions.primary.variant}
                      disabled={actions.primary.disabled}
                      loading={actions.primary.loading}
                      data-testid="modal-primary-action"
                    >
                      {actions.primary.text}
                    </Button>
                  )}
                </div>
              )}
            </DialogPrimitive.Content>
          </>
        )}
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
}

export default Modal
