import Link from 'next/link'

import { LogoWordmark } from '@snipfeed/icons/custom'
import Image from '@snipfeed/image'
import { Button, Notification } from '@snipfeed/tint2'

import creatorstoreAuthImage from '@/public/pictures/account-creation/creatorstore-auth-image.png'

export default function AuthLayout({ children, hasPromoImage = true }) {
  const navigatorRef = typeof navigator !== 'undefined' && navigator

  return (
    <div className="bg-neutral-50 relative w-full h-full flex flex-col md:flex-row">
      <main className="relative w-full md:overflow-y-auto z-10">
        <div className="w-full p-8">
          <nav className="mb-12">
            <a
              aria-label="Logo"
              href={process.env.NEXT_PUBLIC_PLANOLY_URL || '/'}
            >
              <LogoWordmark width={174} height={18} />
            </a>
          </nav>
          {navigatorRef && !navigatorRef.cookieEnabled && (
            <div className="my-5">
              <Notification
                //@ts-expect-error fix Notification component
                type="error"
                actions={
                  <Link
                    href="https://www.whatismybrowser.com/guides/how-to-enable-cookies/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <Button variant="secondary">Enable cookies</Button>
                  </Link>
                }
                message="Cookies are disabled! Please enable them to continue, Planoly requires cookies for login and authentication purposes."
              ></Notification>
            </div>
          )}
          <div className="flex flex-col sm:px-12 items-center justify-center">
            {children}
          </div>
        </div>
      </main>
      {/* Logo and Clouds */}
      {hasPromoImage && (
        <div className=" relative w-full hidden md:flex items-center justify-center py-14 md:overflow-hidden z-10">
          <div className="relative w-full max-w-full flex justify-center">
            <Image
              alt={'Join the thousands of creators on Planoly'}
              priority
              src={creatorstoreAuthImage}
              className="max-w-[60%] min-w-[432px] max-h-[59%] min-h-[604px]"
            />
          </div>
        </div>
      )}
    </div>
  )
}
